import {WabelModel} from './wabel-model.abstract';
import {Member} from './member';
import {Event} from './event';
import {Company} from './company';
import {Contact} from '@services/messenger/contact';
import {Contactable} from './interfaces/contactable.interface';
import {Summit} from './summit';
import {RegistrationNight} from './registration_night';
import {RegistrationAvailability} from './registration_availability';
import {SummitRegistration} from './summit_registration';
import {Meeting} from './meeting';
import {EventWorkshopPreference} from './event_workshop_preference';
import {RegistrationPresenceday} from './registration_presenceday';
import {MeetingPreference} from './meeting_preference';
import {Tag} from './tag';
import {WebRTCResult} from './webrtc_result';
import {MemberRole} from './member_role';
import {Role} from './role';
import {EventParticipation} from './event_participation';
import {ListRowInterface} from './interfaces/list-row.interface';
import {SourcingProject} from '@models/sourcing_project';
import {SourcingRequest} from '@models/sourcing_request';
import * as moment from "moment";

export class Registration extends WabelModel implements Contact, Contactable, ListRowInterface {

  rowIndex = 0;
  formattedNightDates: {
    date: Date,
    formattedDate: string
  }[] = [];

  constructor(obj?: any) {
    super(obj);
    if (obj) {
      this.idcertaineventregistration = obj.idcertaineventregistration ? +obj.idcertaineventregistration : 0;
      this.member = obj.member ? new Member(obj.member) : null;
      this.event = obj.event ? new Event(obj.event) : null;
      this.dateCreated = obj.dateCreated ? new Date(obj.dateCreated) : null;
      this.availabilityStart = obj.availabilityStart ? new Date(obj.availabilityStart) : null;
      this.rawAvailabilityStart = obj.availabilityStart ? obj.availabilityStart : null;
      this.availabilityEnd = obj.availabilityEnd ? new Date(obj.availabilityEnd) : null;
      this.rawAvailabilityEnd = obj.availabilityEnd ? obj.availabilityEnd : null;
      this.availabilities = obj.availabilities && obj.availabilities.items ? obj.availabilities.items.map(availability => new RegistrationAvailability(availability)) : [];
      this.summits = obj.summits ? obj.summits.map(summit => new Summit(summit)) : [];
      this.agendaRef = obj.agendaRef ? new Registration(obj.agendaRef) : null;
      this.nights = obj.nights ? obj.nights.map(night => new RegistrationNight(night)) : [];
      this.nights.sort((a: RegistrationNight, b: RegistrationNight) => a.date.getTime() - b.date.getTime());
      this.computeFormattedNightDates();
      this.summitRegistrations = obj.summitRegistrations && obj.availabilities.items ? obj.summitRegistrations.items.map(sr => new SummitRegistration(sr)) : null;
      this.meetings = obj.displayedMeetings && obj.displayedMeetings.items ? obj.displayedMeetings.items.map(m => new Meeting(m)) : [];
      this.meetings = obj.meetings && obj.meetings.items ? obj.meetings.items.map(m => new Meeting(m)) : [];
      this.selection = obj.selection ? obj.selection.map(c => new Company(c)) : [];
      this.numberOfWorkshops = obj.eventWorkshopPreferences && obj.eventWorkshopPreferences.count ? obj.eventWorkshopPreferences.count : 0;
      this.workshopPreferences = obj.eventWorkshopPreferences && obj.eventWorkshopPreferences.items ? obj.eventWorkshopPreferences.items.map(wp => new EventWorkshopPreference(wp)) : [];
      this.numberOfSelection = obj.selections && obj.selections.count ? obj.selections.count : 0;
      this.selections = obj.selections && obj.selections.items ? obj.selections.items.map(s => new MeetingPreference(s)) : [];
      this.numberOfSelectedRecommendations = obj.selectedRecommendations && obj.selectedRecommendations.count ? obj.selectedRecommendations.count : 0;
      this.selectedRecommendations = obj.selectedRecommendations && obj.selectedRecommendations.items ? obj.selectedRecommendations.items.map(s => new MeetingPreference(s)) : [];
      this.numberOfRemovedRecommendations = obj.removedRecommendations && obj.removedRecommendations.count ? obj.removedRecommendations.count : 0;
      this.removedRecommendations = obj.removedRecommendations && obj.removedRecommendations.items ? obj.removedRecommendations.items.map(s => new MeetingPreference(s)) : [];
      this.numberOfExclusion = obj.exclusions && obj.exclusions.count ? obj.exclusions.count : 0;
      this.exclusions = obj.exclusions && obj.exclusions.items ? obj.exclusions.items.map(s => new MeetingPreference(s)) : [];
      this.numberOfProductSelection = obj.numberOfProductSelection && obj.numberOfProductSelection.count ? obj.numberOfProductSelection.count : 0;
      this.selectionStartedDate = obj.selectionStartedDate ? new Date(obj.selectionStartedDate) : null;
      this.selectionFinishedDate = obj.selectionFinishedDate ? new Date(obj.selectionFinishedDate) : null;
      this.presenceDays = obj.presenceDays && obj.presenceDays.items ? obj.presenceDays.items.map(presenceDay => new RegistrationPresenceday(presenceDay)) : [];
      this.emptySlots = obj.emptySlots ? obj.emptySlots.map(s => new Date(s)) : [];
      this.summitRegistrationCategories = obj.summitRegistrationCategories && obj.summitRegistrationCategories.items ? obj.summitRegistrationCategories.items.map(category => new Tag(category)) : [];
      this.eventPotentialOwner = obj.eventPotentialOwner ? new Member(obj.eventPotentialOwner) : null;
      this.buyingScope = obj.buyingScope && obj.buyingScope.items ? obj.buyingScope.items.map(category => new Tag(category)) : (obj.buyingScope && obj.buyingScope.length ? obj.buyingScope.map(category => new Tag(category)) : []);
      this.lastWebRtcTest = obj.lastWebRtcTest ? new WebRTCResult(obj.lastWebRtcTest) : null;
      this.memberRoles = obj.memberRoles ? obj.memberRoles.map(memberRole => new MemberRole(memberRole)) : [];
      this.participation = obj.participation ? new EventParticipation(obj.participation) : null;
      this.nbOfOkMeetings = obj.nbOfOkMeetings && obj.nbOfOkMeetings.count ? obj.nbOfOkMeetings.count : 0;
      this.nbOfInternalRescheduleMeetings = obj.nbOfInternalRescheduleMeetings && obj.nbOfInternalRescheduleMeetings.count ? obj.nbOfInternalRescheduleMeetings.count : 0;
      this.nbOfExternalRescheduleMeetings = obj.nbOfExternalRescheduleMeetings && obj.nbOfExternalRescheduleMeetings.count ? obj.nbOfExternalRescheduleMeetings.count : 0;
      this.nbOfRecommendations = obj.nbOfRecommendations && obj.nbOfRecommendations.count ? obj.nbOfRecommendations.count : 0;
      this.nbOfTotalMeetings = obj.nbOfTotalMeetings && obj.nbOfTotalMeetings.count ? obj.nbOfTotalMeetings.count : 0;
      this.nbMeetingPref = obj.nbMeetingPref && obj.nbMeetingPref.count ? obj.nbMeetingPref.count : 0;
      this.eventWorkshopPreferencesCount = obj.eventWorkshopPreferences && obj.eventWorkshopPreferences.count ? obj.eventWorkshopPreferences.count : 0;
      this.otherAttributes = {};
      this.sourcingProjects = obj.sourcingProjects && obj.sourcingProjects.items ? obj.sourcingProjects.items.map(sp => new SourcingProject(sp)) : [];
      this.sourcingRequests = obj.sourcingRequests && obj.sourcingRequests.items ? obj.sourcingRequests.items.map(sp => new SourcingRequest(sp)) : [];
      this.meetingPreferences = obj.meetingPreferences && obj.meetingPreferences.items ? obj.meetingPreferences.items.map(mp => new MeetingPreference(mp)) : [];
      this.registrationTags = obj.registrationTags && obj.registrationTags.items ? obj.registrationTags.items.map(mp => new Tag(mp)) : [];
      this.firstMeeting = obj.firstMeeting ? new Meeting(obj.firstMeeting) : null;
      this.lastMeeting = obj.lastMeeting ? new Meeting(obj.lastMeeting) : null;
    }
  }

  idcertaineventregistration: number;
  event: Event;
  member: Member;
  bestBadgeLabel: string;
  checkedIn: boolean;
  qrCode: string;
  dateCreated: Date;
  availabilityStart: Date;
  availabilityEnd: Date;
  rawAvailabilityStart: string;
  rawAvailabilityEnd: string;
  availabilities: RegistrationAvailability[] = [];
  noDiary: boolean;
  registrationCode: string;
  selectedBy: Member[] = [];
  excludedBy: Member[] = [];
  selection: Company[] = [];
  exclusion: Company[] = [];
  selections: MeetingPreference[];
  numberOfSelection: number;
  selectedRecommendations: MeetingPreference[];
  numberOfSelectedRecommendations: number;
  removedRecommendations: MeetingPreference[];
  numberOfRemovedRecommendations: number;
  exclusions: MeetingPreference[];
  numberOfExclusion: number;
  workshops: EventWorkshopPreference[];
  numberOfProductSelection: number;
  summitRegistrations: SummitRegistration[];
  buyingScope: Tag[] = [];
  selectionToolUrl: string;
  messageBySms: boolean;
  messageByEmail: boolean;
  otherAttributes: object;
  summits: Summit[] = [];
  nights: RegistrationNight[] = [];
  room: string;
  hotel: string;
  hotelComment: string;
  mustPay: boolean;
  badgeUrl: string;
  scheduleUrl: string;
  badgeScheduleUrl: string;
  meetingRoom: string;
  dateChecking: string;
  summitRegistrationCategories: Tag[];
  meetings: Meeting[];
  workshopPreferences: EventWorkshopPreference[];
  numberOfWorkshops: number;
  buyersAlerts: string[];
  selectionStartedDate: Date;
  selectionFinishedDate: Date;
  eventPotentialOwner: Member;
  agendaRef: Registration;
  presenceDays: RegistrationPresenceday[] = [];
  emptySlots: Date[];
  meetingProvider: string;
  onboardingEmailStatus: string;
  lastWebRtcTest: WebRTCResult;
  memberRoles: Role[] = [];
  participation: EventParticipation;
  eventRoles: string[];
  dateLastAgendaCheck: string;
  dateLastAgendaConfirmation: string;
  nbOfRecommendations: number;
  nbOfOkMeetings: number;
  nbOfInternalRescheduleMeetings: number;
  nbOfExternalRescheduleMeetings: number;
  nbOfTotalMeetings: number;
  selectionStatus: string;
  meetingsNumber: number;
  sourcingProjects: SourcingProject[];
  sourcingRequests: SourcingRequest[];
  nbMeetingPref: number;
  eventWorkshopPreferencesCount: number;
  nbMeetingPreferencesDeleted: number;
  nbMeetingPreferencesPending: number;
  nbMeetingPreferencesSelected: number;
  meetingPreferences: MeetingPreference[];
  sourcingDaysStatus: string;
  registrationTags: Tag[];
  firstMeeting: Meeting;
  lastMeeting: Meeting;

  private checkinDateTZ: Date;

  get id() {
    return this.idcertaineventregistration;
  }

  get email() {
    return this.member.email;
  }

  get name()
    :
    string {
    return this.member.fullname;
  }

  get phone()
    :
    string {
    return this.member.mobile_event;
  }

  get emailAuthorized()
    :
    boolean {
    return this.messageByEmail || false;
  }

  get smsAuthorized()
    :
    boolean {
    return this.messageBySms || false;
  }

  getContact(attributes
               :
               object = null
  ):
    Contact {
    this.otherAttributes = attributes;
    return {
      email: this.email,
      name: this.name,
      phone: this.phone,
      emailAuthorized: this.emailAuthorized,
      smsAuthorized: this.smsAuthorized,
      otherAttributes: this.otherAttributes
    };
  }

  get formattedAvailabilityStart() {
    return this.availabilityStart.toLocaleString();
  }

  get formattedAvailabilityEnd() {
    return this.availabilityEnd.toLocaleString();
  }

  checkinDateEvent(timezone: string): Date {
    if (this.checkinDateTZ) {
      return this.checkinDateTZ;
    }
    let computerTimeZone = moment.tz.guess();
    let dateString = this.dateChecking.split('+')[0];
    const eventDateTz = moment.tz(dateString, timezone);
    this.checkinDateTZ = moment.tz(eventDateTz.format('YYYY-MM-DDTHH:mm:ss'), computerTimeZone).toDate();
    return this.checkinDateTZ;
  }

  get firstNight() {
    return this.nights.length > 0 ? this.nights[0].date : null;
  }

  get lastNight() {
    return this.nights.length > 0 ? this.nights[this.nights.length - 1].date : null;
  }

  get checkoutDate() {
    const lastNightDate = this.lastNight;

    if (lastNightDate) {
      const checkoutDate = new Date(lastNightDate);
      checkoutDate.setDate(checkoutDate.getDate() + 1);
      return checkoutDate;
    }

    return null;
  }

  get nbNights() {
    return this.nights.length;
  }

  private computeFormattedNightDates() {
    this.formattedNightDates = this.nights.map((night) => {
      return {
        date: night.date,
        formattedDate: this.formatDate(night.date),
      };
    });
  }
}

